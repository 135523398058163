import React, { FC } from 'react';
import { motion } from 'framer-motion';
import localStyles from './Section.module.css';
import { useInView } from 'react-intersection-observer';

export interface SectionProps {
    children: React.ReactNode;
    styles?: {
        container?: string;
        innerContainer?: string;
    };
    height?: string;
    threshold?: number;
    extraStyles?: object;
    extraClassNames?: string;
    name?: string;
    transition?: any;
    noPadding?: boolean;
    variants?: {
        container: { enter: object; from: object; exit: object };
    };
}

const Section: FC<SectionProps> = ({
    children,
    name,
    styles = localStyles,
    extraStyles,
    extraClassNames = '',
    threshold = 0.05,
    height = 'fit-content',
    transition = { staggerChildren: 0.3 },
    noPadding = false,
    variants = { container: { enter: {}, from: {}, exit: {} } },
}) => {
    //console.log(typeof styles);

    const [ref, inView] = useInView({
        threshold: threshold,
        triggerOnce: true,
    });

    return (
        <section
            {...(name ? { id: '#section-' + name } : {})}
            ref={ref}
            style={{ height: height, ...(noPadding ? { padding: 0 } : {}), ...extraStyles }}
            data-scroll-section
            className={`${styles.container} ${extraClassNames}`}
        >
            <motion.div
                className={styles.innerContainer}
                transition={transition}
                initial="from"
                key={inView ? '1' : '2'}
                animate={inView ? 'enter' : 'from'}
                exit="exit"
                variants={variants.container}
            >
                {children}
            </motion.div>
        </section>
    );
};

export default Section;
