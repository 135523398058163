import React, { FunctionComponent as FC } from 'react';
import localLogoDark from './img/unom_logo_dark.svg';
import localLogoBright from './img/unom_logo_bright.svg';
import { motion, Variants } from 'framer-motion';
import localStyles from './Logo.module.css';
import localAnimation from './unomLogoAnimation.json';
import Animation from 'Components/Containers/Animation';
import ease from '@unom/unom-style/easings';

export interface LogoProps {
    /**
     * The src of the Logo, pass your imported img, e.g. import img from "./img.png" and <Logo src={img}/>
     */
    src?: string | { dark: string; bright: string };
    /**
     * Animated logo, in json/lottie format, e.g. import animation from "./animation.json" and <Logo animation={animation}/>
     */
    animation?: any;
    secondary?: boolean;
    neutral?: boolean;
    AnimationComponent?: any;
    dark?: boolean;
    /**
     * An imported CSS module containing the following classes
     */
    styles?: {
        container?: string;
        neutral?: string;
        secondary?: string;
    };
    onClick?: () => void;
    component?: React.ReactNode;
    transition?: object;
    animated?: boolean;
    variants?: Variants;
}

const Logo: FC<LogoProps> = ({
    src = { bright: localLogoBright, dark: localLogoDark },
    dark = false,
    animated = false,
    styles = localStyles,
    AnimationComponent,
    animation,
    secondary,
    neutral,
    component,
    onClick,
    transition = ease.quint(1.2).out,
    variants = {
        enter: {
            scale: 1,
        },
        from: {
            scale: 0,
        },
        hover: {
            transition: ease.quint(0.5).out,
            scale: 0.9,
        },
    },
}) => {
    return (
        <motion.div
            initial="from"
            animate="enter"
            exit="exit"
            whileHover="hover"
            whileTap="tap"
            onClick={() => (onClick ? onClick() : null)}
            transition={transition}
            variants={variants}
            className={`${styles.container} ${secondary ? styles.secondary : ''} ${neutral ? styles.neutral : ''}`}
        >
            {component ? (
                component
            ) : animated && AnimationComponent ? (
                <>
                    <svg style={{ width: 0, height: 0, position: 'absolute' }} aria-hidden="true" focusable="false">
                        <linearGradient id="unom-logo-gradient" x2="1" y2="1">
                            <stop offset="0%" stopColor="var(--color-main)" />
                            <stop offset="100%" stopColor="var(--color-neutral-complement)" />
                        </linearGradient>
                    </svg>
                    <Animation
                        AnimationComponent={AnimationComponent}
                        animationData={animation ? animation : localAnimation}
                        isStopped={false}
                        isPaused={false}
                    />
                </>
            ) : typeof src === 'string' ? (
                <img src={src} />
            ) : (
                src.bright && <img src={dark && src.dark ? src.dark : src.bright} />
            )}
        </motion.div>
    );
};

export default Logo;
