import React, { FC, useEffect, useRef } from 'react';
import localStyles from './WrapperScroll.module.css';
import LocomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/dist/locomotive-scroll.css';

export interface ScrollService {
    update: () => void;
    enable: () => void;
    scrollTo: (
        target: HTMLElement | number,
        options?: {
            lerp?: number;
            offset?: number;
            duration?: number;
            callback?: () => void;
            disableLerp?: boolean;
            easing?: [number, number, number, number];
        },
    ) => void;
    disable: () => void;
}

let scrollService: ScrollService = {
    update: () => null,
    scrollTo: () => null,
    disable: () => null,
    enable: () => null,
};

const ScrollContext = React.createContext(scrollService);

export interface WrapperScrollProps {
    smoothScroll?: {
        enabled: boolean;
        enabledMobile: boolean;
        repeat: boolean;
    };
    getSpeed?: boolean;
    firefoxMultiplier?: number;
    getDirection?: boolean;
    location?: string;
    lerp?: number;
    onScroll?: (data) => void;
    styles?: {
        container: string;
    };
}

const WrapperScroll: FC<WrapperScrollProps> = ({
    smoothScroll = {
        enabled: true,
        enabledMobile: false,
        repeat: false,
    },
    getSpeed = false,
    getDirection = true,
    firefoxMultiplier = 50,
    lerp = 0.1,
    onScroll,
    location,
    styles = localStyles,
    children,
}) => {
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (container) {
            const ScrollHandler = new LocomotiveScroll({
                el: container.current,
                smooth: smoothScroll.enabled,
                smoothMobile: smoothScroll.enabledMobile,
                repeat: smoothScroll.repeat,
                getDirection: getDirection,
                firefoxMultiplier: firefoxMultiplier,
                lerp: lerp,
                getSpeed: getSpeed,
            });

            ScrollHandler.update();

            scrollService = {
                update: (): void => ScrollHandler.update(),
                scrollTo: (target, options): void => ScrollHandler.scrollTo(target, options),
                disable: (): void => ScrollHandler.disable(),
                enable: (): void => ScrollHandler.enable(),
            };

            if (onScroll) {
                ScrollHandler.on('scroll', onScroll);
            }

            setTimeout(() => ScrollHandler.update(), 300);
            setTimeout(() => ScrollHandler.update(), 500);

            return (): void => {
                if (ScrollHandler) ScrollHandler.destroy();
            };
        }
        return;
    }, [location]);

    return (
        <ScrollContext.Provider value={scrollService}>
            <div className={styles.container} data-scroll-container ref={container}>
                {children}
            </div>
        </ScrollContext.Provider>
    );
};

export { scrollService };

export default WrapperScroll;
