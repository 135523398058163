import React, { FC } from 'react';
import localStyles from './Link.module.css';
import { motion, Variants } from 'framer-motion';
import ease from '@unom/unom-style/easings';
import { FontSize, RouterLinkComponentType } from 'types/common';

export type LinkProps = {
    href?: string;
    neutral?: boolean;
    secondary?: boolean;
    weight?: number;
    size?: FontSize;
    to?: string;
    transition?: {
        container?: object;
        link?: object;
    };
    styles?: {
        container?: string;
        link?: string;
        neutral?: string;
        secondary?: string;
    };
    extraStyles?: {
        container?: object;
        link?: object;
    };
    variants?: {
        container?: Variants;
        link?: Variants;
    };
};

const Link: FC<LinkProps & { RouterLinkComponent?: RouterLinkComponentType }> = ({
    href,
    to,
    RouterLinkComponent,
    extraStyles = {
        container: {},
        link: {},
    },
    transition = {
        container: ease.quint(0.9).out,
        link: ease.quint(0.8),
    },
    styles = localStyles,
    children,
    size,
    neutral = false,
    weight = 500,
    secondary = false,
    variants = {
        container: { from: { opacity: 0, y: '10px' }, enter: { opacity: 1, y: 0 }, exit: {} },
        link: { from: {}, enter: {}, exit: {} },
    },
}) => {
    return (
        <motion.div
            transition={transition.container}
            style={extraStyles.container}
            whileHover="hover"
            variants={variants.container}
            className={`${styles.container} ${secondary ? styles.secondary : ''} ${neutral ? styles.neutral : ''}`}
        >
            {to && RouterLinkComponent ? (
                <RouterLinkComponent
                    style={{
                        ...extraStyles.link,
                        fontWeight: weight,
                        ...(size ? { fontSize: 'var(--font-size-' + size + ')' } : {}),
                    }}
                    className={styles.link}
                    to={to}
                >
                    {children}
                </RouterLinkComponent>
            ) : (
                <a
                    style={{
                        ...extraStyles.link,
                        fontWeight: weight,
                        ...(size ? { fontSize: 'var(--font-size-' + size + ')' } : {}),
                    }}
                    className={styles.link}
                    href={href}
                >
                    {children}
                </a>
            )}
        </motion.div>
    );
};

export default Link;
