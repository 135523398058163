import React, { useMemo, FunctionComponent as FC } from 'react';

export interface Props {
    isPaused?: boolean;
    isStopped?: boolean;
    AnimationComponent: any;
    animationData: any;
}

const AnimationContainer: FC<Props> = ({ AnimationComponent, isPaused = false, isStopped = false, animationData }) => {
    return useMemo(() => {
        return (
            <AnimationComponent
                options={{
                    autoplay: true,
                    loop: false,
                    animationData: animationData,
                    renderer: 'svg',
                }}
                height="100%"
                width="100%"
                isStopped={isStopped}
                isPaused={isPaused}
            />
        );
    }, [isStopped, isPaused]);
};

export default AnimationContainer;
