import React, { FC, ReactElement } from 'react';
import { motion } from 'framer-motion';
import localStyles from './Text.module.css';
import ease from '@unom/unom-style/easings';

export interface TextProps {
    tag: string;
    animate?: string;
    variants?: { from?: object; enter?: object; exit?: object };
    initial?: string;
    weight?: string;
    children?: any;
    disableAnimation?: boolean;
    secondary?: boolean;
    neutral?: boolean;
    extraStyles?: object;
    size?: 'xxs' | 'xs' | 's' | 'm' | 'xm' | 'xxm' | 'l' | 'xl' | 'xxl';
    transition?: object;
    styles?: { container?: string; secondary?: string; neutral?: string };
    exit?: string;
}

export interface TextPropsMapped extends Omit<TextProps, 'tag'> {
    tag?: string;
}

const Text: FC<TextProps> = ({
    variants = { from: { y: '20px', opacity: 0 }, enter: { y: 0, opacity: 1 } },
    tag = 'h1',
    children,
    disableAnimation,
    transition = ease.circ(0.9).out,
    animate,
    weight,
    initial,
    extraStyles,
    neutral = false,
    secondary = false,
    size,
    styles = localStyles,
    exit,
}) => {
    const Tag = disableAnimation ? tag : motion[tag];

    return (
        <Tag
            {...(disableAnimation
                ? {}
                : {
                      ...(exit ? { exit: exit } : {}),
                      ...(animate ? { animate: animate } : {}),
                      ...(initial ? { initial: initial } : {}),
                      variants: variants,
                      transition: transition,
                  })}
            style={{
                ...(size ? { fontSize: `var(--font-size-${size})` } : {}),
                ...(weight ? { fontWeight: weight } : {}),
                ...(extraStyles ? extraStyles : {}),
            }}
            className={`${styles.container} ${secondary ? styles.secondary : ''} ${neutral ? styles.neutral : ''}`}
        >
            {children}
        </Tag>
    );
};

const tags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'a', 'p'];

const mappedTags = Object.fromEntries(
    tags.map((i) => [i, (props: TextPropsMapped): ReactElement => <Text tag={i} {...props} />]),
);

export default mappedTags;
