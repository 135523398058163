import React, { FC } from 'react';
import Section from 'Components/Section';
import Link from 'Components/Link';
import localStyles from './Footer.module.css';
import Text from 'Components/Text';
import { motion } from 'framer-motion';
import ease from '@unom/unom-style/easings';
import { RouterLinkComponentType } from 'types/common';

export type FooterProps = {
    styles?: {
        container?: string;
    };
    RouterLinkComponent?: RouterLinkComponentType;
};

const Footer: FC<FooterProps> = ({ styles = localStyles, RouterLinkComponent }) => {
    const variants = {
        container: {
            from: {
                y: '101%',
            },
            enter: {
                y: 0,
            },
        },
    };

    const sections = [
        {
            id: 0,
            title: 'Rechtliches',
            links: [
                {
                    id: 0,
                    name: 'Impressum',
                    to: '/impressum',
                },
                {
                    id: 1,
                    name: 'Datenschutz',
                    to: '/datenschutz',
                },
            ],
        },
    ];

    return (
        <Section extraStyles={{ overflow: 'hidden' }} noPadding height="300px">
            <motion.div
                transition={{ ...ease.circ(1.9).out, staggerChildren: 0.3 }}
                variants={variants.container}
                className={styles.container}
            >
                {sections.map((section) => (
                    <div key={section.id}>
                        <Text.h4 extraStyles={{ marginBottom: '0.5em' }} size="m">
                            {section.title}
                        </Text.h4>
                        {section.links.map((link) => (
                            <Link
                                {...(RouterLinkComponent ? { RouterLinkComponent: RouterLinkComponent } : {})}
                                extraStyles={{ container: { marginBottom: '0.25em' } }}
                                key={link.id}
                                to={link.to}
                            >
                                {link.name}
                            </Link>
                        ))}
                    </div>
                ))}
            </motion.div>
        </Section>
    );
};

export default Footer;
