import React, { FunctionComponent as FC } from 'react';
import LocalFooter from 'Components/Footer';
import { RouterLinkComponentType, RouterRouteComponentType } from 'types/common';

export type FooterProps = {
    children?: any;
    height?: string;
    path?: string;
    exact?: boolean;
    showHeader?: boolean;
    header?: React.ReactNode;
    location?: object;
    RouterLinkComponent: RouterLinkComponentType;
    RouterRouteComponent: RouterRouteComponentType;
    footer?: React.ReactNode;
    extraStyles?: {
        container?: object;
        innerContainer?: object;
    };
};

const Route: FC<FooterProps> = ({
    extraStyles,
    showHeader = false,
    children,
    height,
    path,
    footer,
    exact,
    header,
    RouterRouteComponent,
    RouterLinkComponent,
}) => {
    const RouteComponentContainer = (
        <RouterRouteComponent
            path={path}
            {...(location ? location : {})}
            exact={exact}
            render={() => (
                <>
                    {showHeader ? (
                        header ? (
                            header
                        ) : (
                            <div>
                                <h1>Header</h1>
                            </div>
                        )
                    ) : null}
                    {children}
                    {footer ? (
                        footer
                    ) : (
                        <LocalFooter {...(RouterLinkComponent ? { RouterLinkComponent: RouterLinkComponent } : {})} />
                    )}
                </>
            )}
        />
    );

    return <div style={{ ...(height ? { height: height } : null), ...extraStyles }}>{RouteComponentContainer}</div>;
};

export default Route;
