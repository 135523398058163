import React, { FunctionComponent as FC } from 'react';
import { motion, Variants } from 'framer-motion';
import localStyles from './Button.module.css';
import ease from '@unom/unom-style/easings';
import { FontSize } from 'types/common';

export type ButtonProps = {
    RouterLinkComponent?: React.ComponentType<{ href?: string; to?: string }>;
    to?: string;
    variants?: {
        container: Variants;
    };
    scrollTo?: string;
    onClick?: () => void;
    size?: FontSize;
    styles?: {
        container?: string;
        button?: string;
        secondary?: string;
    };
    secondary?: boolean;
    RouterLinkComponentPropKey?: string;
    transition?: object;
    type?: 'button' | 'submit' | 'reset';
    scrollService?: {
        scrollTo: (target: HTMLElement) => void;
    };
    href?: string | boolean;
    extraClassNames?: string;
    extraStyles?: {
        container?: object;
        button?: object;
    };
};

const Button: FC<ButtonProps> = ({
    extraStyles,
    children,
    to,
    size,
    RouterLinkComponentPropKey,
    scrollService,
    RouterLinkComponent = (props): React.ReactElement => <a {...props} />,
    extraClassNames,
    type,
    scrollTo,
    onClick = (): void => {
        if (scrollTo && scrollService) {
            const target = document.getElementById(scrollTo);
            if (target) {
                scrollService.scrollTo(target);
            }
        }
    },
    secondary = false,
    transition = ease.quint(0.7).out,
    styles = localStyles,
    href,
    variants = {
        container: {
            enter: { scale: 1, transition: ease.circ(1.4).out },
            from: { scale: 0 },
            exit: { scale: 0 },
            hover: {},
            press: {},
        },
    },
}) => {
    const buttonCommonProps = {
        className: `${styles.button} ${secondary ? styles.secondary : ''} ${extraClassNames ? extraClassNames : ''}`,
        style: { ...extraStyles?.button, ...(size ? { fontSize: 'var(--font-size-' + size + ')' } : {}) },
    };

    return (
        <motion.div
            transition={transition}
            className={styles.container}
            {...(variants.container.hover ? { whileHover: 'hover' } : {})}
            {...(variants.container.press ? { whileTap: 'press' } : {})}
            variants={variants.container}
            style={{ ...extraStyles?.container }}
        >
            {to ? (
                <RouterLinkComponent
                    {...((RouterLinkComponentPropKey ? { [RouterLinkComponentPropKey]: to } : { to: to }) as any)}
                >
                    <a {...buttonCommonProps} href={typeof href === 'string' ? href : undefined}>
                        {children}
                    </a>
                </RouterLinkComponent>
            ) : href ? (
                <a {...buttonCommonProps} href={typeof href === 'string' ? href : undefined}>
                    {children}
                </a>
            ) : (
                <button {...(type ? { type: type } : {})} onClick={(): void => onClick()} {...buttonCommonProps}>
                    {children}
                </button>
            )}
        </motion.div>
    );
};

export default Button;
