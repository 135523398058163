import React, { forwardRef, Ref, useImperativeHandle } from 'react';
import localStyles from './Form.module.css';
import { motion } from 'framer-motion';
import ease from '@unom/unom-style/easings';
import Fields from './Fields';
import get from 'lodash.get';
import { useForm } from 'react-hook-form';

export type commonFormChildProps = {
    name: string;
};

export type FormProps = {
    defaultValues?: any;
    children: any;
    styles?: {
        container?: string;
        fieldsContainer?: string;
    };
    onClick?: (event) => void;
    name?: string;
    extraStyles?: object;
    transition?: object;
    onSubmit?: (data: object) => void;
};

const getDefaultValues = (children) => {
    if (Array.isArray(children) && children.length > 0) {
        const filtered = children.filter((child) => child.props.name !== undefined);

        const mapped = filtered.map((child) => ({ [child.props.name]: '' }));

        return Object.assign({}, ...mapped);
    } else return undefined;
};

const Form = forwardRef(
    (
        {
            defaultValues,
            children,
            onClick = (): null => null,
            onSubmit,
            name,
            extraStyles,
            styles = localStyles,
            transition = { ...ease.quint(0.8).out, staggerChildren: 0.1 },
        }: FormProps,
        ref: Ref<{ getValues }>,
    ) => {
        const methods = useForm({
            ...defaultValues,
            defaultValues:
                defaultValues && defaultValues.defaultValues ? defaultValues.defaultValues : getDefaultValues(children),
        });
        const { handleSubmit, formState, getValues } = methods;
        const { errors } = formState;
        useImperativeHandle(ref, () => ({ getValues }));

        const variants = {
            container: {
                enter: {},
                from: {},
            },
        };

        return (
            <motion.div
                onClick={onClick}
                style={extraStyles}
                variants={variants.container}
                transition={transition}
                className={styles.container}
            >
                <form
                    name={name}
                    className={styles.fieldsContainer}
                    onSubmit={handleSubmit((data) => onSubmit && onSubmit(data))}
                >
                    {Array.isArray(children)
                        ? children.map((child) => {
                              return child.props.name
                                  ? React.createElement(child.type, {
                                        ...{
                                            ...child.props,
                                            name: child.props.name,
                                            touched: get(formState.touchedFields, child.props.name),
                                            dirty:
                                                defaultValues?.defaultValues &&
                                                defaultValues.defaultValues[child.props.name]
                                                    ? true
                                                    : get(formState.dirtyFields, child.props.name),
                                            register: methods.register,
                                            error: errors[child.props.name],
                                            key: child.props.name,
                                        },
                                    })
                                  : child;
                          })
                        : children}
                </form>
            </motion.div>
        );
    },
);

Form.displayName = 'Form';

export { Form, Fields };
