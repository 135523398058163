import React, { FunctionComponent as FC } from 'react';
import { motion } from 'framer-motion';
import Text from 'Components/Text';

export interface Props {
    code: '404' | '403';
}

const SiteError: FC<Props> = ({ code }) => {
    return (
        <motion.div style={{ minHeight: '100vh' }}>
            <Text.h1>Error {code}</Text.h1>
        </motion.div>
    );
};

export default SiteError;
